import { getObjectKeys } from "@avvoka/shared"
import { isVitestEnv } from "./vitest"

declare global {
  interface Window {
    application: {
      LocalizationData: LocalizationData
      TimeZone: string
      AppName: string
      IsDevelopment: boolean
      Links: {
        signInHelp: string
        avvokaWebsite: string
        footerLinks: {url:string, label:string}[]
      }
    }
  }
}

const applicationData: Window['application'] = isVitestEnv ? {
  LocalizationData: {},
  AppName: 'Avvoka',
  TimeZone: 'Europe/London',
  IsDevelopment: true,
  Links: {
    signInHelp: 'https://help.avvoka.com/en/articles/5830454-how-do-i-access-avvoka-if-i-don-t-have-an-account',
    avvokaWebsite: 'https://www.avvoka.com/',
    footerLinks: []
  }
} : window.application

// Type for localization data, value can be string or another level of localization
interface LocalizationData {
  [T: string]: string | LocalizationData
}

type LocalizationVariables = Record<string, unknown>

type LocalizationMethod = (key: string, localArgs?: LocalizationVariables) => string

// Assert correct types
function _isNamespace (source: ReturnType<typeof _retrieve>): source is LocalizationData {
  return !!source && typeof source === 'object'
}

function _isLocalization (source: ReturnType<typeof _retrieve>): source is string {
  return typeof source === 'string'
}

// Helper to retrieve data from nested json
function _retrieve (source: LocalizationData, path: string): string | LocalizationData | null {
  const items = path.split('.')

  // Dig in the localization data and try to retrieve the text
  let text: string | LocalizationData = source
  for (const item of items) {
    text = text[item]
    if (!text || typeof text !== 'object') {
      break
    }
  }

  return text
}

// Generate localized text from path
function _localizePath (path: string, namespace?: string) {
  if (applicationData.IsDevelopment) {
    return namespace ? `${namespace}.${path}` : path
  } else {
    const items = path.split('.')
  
    return items[items.length - 1].split('_').map((word) => word.replace(/^\w/, (c) => c.toUpperCase())).join(' ')
  }
}

// Global method for localization, queries localization data
function _localize (source: LocalizationData, path: string, localArgs?: LocalizationVariables, namespaceArgs?: LocalizationVariables, namespace?: string) {
  let text = _retrieve(source, path)

  // Check whether text is valid (must be string)
  if (!_isLocalization(text)) {
    console.warn(`[Localization] Missing or invalid translation for '${namespace ? `${namespace}.` : ''}${path}' `)

    return _localizePath(path, namespace)
  }

  // Skip variable embedding if string does not include % symbol
  if (text.indexOf('%') === -1) return text

  // If args is present, try to insert variables
  if (localArgs && typeof localArgs === 'object') {
    for (const key of getObjectKeys(localArgs)) text = text.replaceAll(`%{${key}}`, String(localArgs[key]))
  }

  if (namespaceArgs && typeof namespaceArgs === 'object') {
    for (const key of getObjectKeys(namespaceArgs)) text = text.replaceAll(`%{${key}}`, String(namespaceArgs[key]))
  }

  for (const key of PREDEFINED_VARIABLES_KEYS) {
    text = text.replaceAll(`%{${key}}`, String(PREDEFINED_VARIABLES[key]))
  }

  return text
}

// Local method to avoid duplication
export function globalLocalize (key: string, localArgs?: Record<string, unknown>) {
  return _localize(applicationData.LocalizationData, key, localArgs)
}

export function useLocalize (namespace: string = '', namespaceArgs?: LocalizationVariables): LocalizationMethod & {
  /**
   * Localize key without prepending current namespace
   */
  global: LocalizationMethod
} {
  const source = namespace ? _retrieve(applicationData.LocalizationData, namespace) : applicationData.LocalizationData
  if (!_isNamespace(source)) {
    console.warn(`[Localization] Namespace '${namespace}' is not a valid namespace`)

    // Return 'fake' localization function
    const localize = (key: string) => _localizePath(key, namespace)
    localize.global = globalLocalize

    return localize
  }

  const localize = (key: string, localArgs?: LocalizationVariables) => _localize(source, key, localArgs, namespaceArgs, namespace)
  localize.global = globalLocalize

  return localize
}

export function getUserTimeZone () {
  return applicationData.TimeZone || 'Europe/London'
}

export function getAppName () {
  return applicationData.AppName || 'Avvoka'
}

// Setup predefined variables, only after everything is configured
const PREDEFINED_VARIABLES: Record<string, unknown> = {
  'application.name': applicationData.AppName
}

const PREDEFINED_VARIABLES_KEYS = Object.keys(PREDEFINED_VARIABLES)

export const _spec = {
  _isNamespace,
  _isLocalization,
  _retrieve,
  _localize,
  _applicationData: applicationData
}